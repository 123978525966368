
const Arr =[
	{
		id:18,
		image:'./images/medical_kit.jpeg',
		Name:"Save lives by donating",
		price:"₹15,00,000",
		title:"Distribution Medical Kits ",
		descriptions:"Distribution Medical Kits to needy orphans & senior citizens and mentally disabled people ",
        catogaries:'Medical',
		to:'/medical_kits_event',
	},
	{
		id:17,
		image:'./images/food_donation.jpeg',
		Name:"Orphans",
		price:"₹25,00,000",
		title:"Feeding The Hungry",
		descriptions:"Distribution food to needy orphans & senior citizens and mentally disabled people ",
        catogaries:'Orphan',
		to:'/food_donation_event',
	},
	{
		id:1,
		image:'./images/7.png',
		Name:"Girls' Education",
		price:"₹33,00,000",
		title:"Supporting Girls' Education",
		descriptions:"Every day, girls face of barriers to education caused by poverty, cultural norms and practices, poor Infrastructure ..",
        catogaries:'Education',
		
		to:'/event-seven',
	
	},
	{
		id:4,
		image:'./images/10.png',
		Name:"Save lives by donating",
		price:"₹15,00,000",
		title:"Fundraise for your loved once",
		descriptions:"Start a fundraiser for your loved one.Our goal at AkoDa is to provide a donation and fundraising Platform where ..",
        catogaries:'Medical',
		to:'/event-ten',
	},
	{
		id:3,
		image:'./images/8.png',
		Name:"AkoDa for girls",
		price:"₹55,00,000",
		title:"If you want change, invest in Girls' education now!",
		descriptions:"“If we are going to see real development in the world ,then our best investment is for WOMEN!” Donate through AkoDa.",
        catogaries:'Education',
		to:'/event-eight',
	},
	{
		id:5,
		image:'./images/11.png',
		Name:"Giving Hope",
		price:"₹56,00,000",
		title:"Start a fundraising for medical Cause",
		descriptions:"Online Medical crowdfunding is an alternative Method for individuals and organizations to Generate funds required ..",
        catogaries:'Medical',
		to:'/event-eleven',
	},	
	
	{
		id:2,
		image:'./images/9.png',
		Name:"Importance of girl's education",
		price:"₹24,00,000",
		title:"Ensuring equal access to education …",
		descriptions:"Girls who receive an education are less likely to Marry young and more likely to lead healthy, Productive lives..",
        catogaries:'Education',
		to:'/event-nine',

	},
	{
		id:6,
		image:'./images/12.png',
		Name:"Medical emergency?",
		price:"₹77,00,000",
		title:" Most medical emergencies ",
		descriptions:"Want to raise funds for an emergency medical Treatment? Don't waste time. Start now. Over 50% Of fundraisers on AkoDa ..",
        catogaries:'Medical',
		to:'/event-tweleve',

	},
		{
		id:8,
		image:'./images/14.png',
		Name:"Start a campaign",
		price:"₹80,00,000",
		title:"When your child is seriously ill.",
		descriptions:"AkoDa offers financial help for a child in India. Raise Funds to pay your medical bills. Raise funds for Medical causes..",
        catogaries:'Children',
		to:'/event-fourteen',
		

	},
	{
		id:9,
		image:'./images/1.png',
		Name:"AkoDa for Child",
		price:"₹45,00,000",
		title:"Fundraise with AkoDa Foundation",
		descriptions:"AkoDa Foundation is a Charitable trust & Non Profit Organization incorporated ... with the objective to help sick children..",
        catogaries:'Children',
		to:'/event-one',
	},

	{
		id:10,
		image:'./images/15.png',
		Name:"Save the planet",
		price:"₹85,00,000",
		title:" Planting trees by AkoDa.",
		descriptions:"Be nice to the Earth in a new way. Explore and Support environmentally friendly projects at AkoDa",
        catogaries:'Environment',
		to:'/event-fifteen',
	},
	
	{
		id:15,
		image:'./images/4.png',
		Name:"Feeding poor",
		price:"₹25,00,000",
		title:"We’ve been feeding hungry People",
		descriptions:"They cannot even afford a single time meal for Themselves and their family, so donating food will make their ..",
        catogaries:'Orphan',
		to:'/event-four',
	},
	{
		id:7,
		image:'./images/13.png',
		Name:"Save the Children",
		price:"₹35,00,000",
		title:"Children fund on AkoDa.",
		descriptions:"There are a number of ways you can raise money to Save the Children! Donate your birthday, run a race..",
        catogaries:'Children',
		to:'/event-thirteen',

	},
	
	
	{
		id:12,
		image:'./images/17.png',
		Name:"AkoDa for environment",
		price:"₹15,00,000",
		title:"AkoDa having another section ",
		descriptions:"Crowdfunding for Protecting Environment- AkoDa Is the best crowdfunding platform to raise funds for environment ..",
        catogaries:'Environment',
		to:'/event-seventeen',
	},	
	{
		id:13,
		image:'./images/2.png',
		Name:"Donate the orphan's",
		price:"₹70,00,000",
		title:"Help those who are in need.",
		descriptions:"Offer Kindness Proactively. Pay attention to the People around you and what you can do to make their lives...",
        catogaries:'Orphan',
		to:'/event-two',
	},
		{
		id:14,
		image:'./images/3.png',
		Name:"Birthday celeb",
		price:"₹90,00,000",
		title:"Donate your Birthday",
		descriptions:"By celebrating our birthdays at an orphanage, we Make orphans feel even more like orphans.",
        catogaries:'Orphan',
		to:'/event-three',
	},	
	// {
	// 	id:16,
	// 	image:'./images/5.png',
	// 	Name:"Helping orphans",
	// 	price:"₹30,00,000",
	// 	title:"A child’s smile is one life’s ",
	// 	descriptions:" “There's nothing more pure than an innocent child's smile. It's just so radiant with unadulterated happiness.”",
    //     catogaries:'Orphan',
	// 	to:'/event-five',
	// },	
	{
		id:11,
		image:'./images/16.png',
		Name:"Start an environment campaign",
		price:"₹12,00,000",
		title:" Planting trees to save the Planet",
		descriptions:"Crowdfunding Campaigns for Environment Conservation • Planting trees for the environment is good as they .. ",
        catogaries:'Environment',
		to:'/event-sixteen',
	},
]
export default Arr ;