import React from 'react'

import gallery1 from './assets/images/gallery/gallery-1.jpg'
import gallery2 from './assets/images/gallery/gallery-2.jpg'
import gallery3 from './assets/images/gallery/gallery-3.jpg'
import gallery4 from './assets/images/gallery/gallery-4.jpg'
import gallery5 from './assets/images/gallery/gallery-5.jpg'
import gallery6 from './assets/images/gallery/gallery-6.jpg'
import gallery7 from './assets/images/gallery/gallery-7.jpg'
import gallery8 from './assets/images/gallery/gallery-8.jpg'
import gallery9 from './assets/images/gallery/gallery-9.jpg'
import gallery10 from './assets/images/gallery/gallery-10.jpg'
import gallery11 from './assets/images/gallery/gallery-11.jpg'
import gallery12 from './assets/images/gallery/gallery-12.jpg'
import im1 from './img/img/1.png'
import { Link } from 'react-router-dom'



const Gallery = () => {
	{ window.scrollTo({ top: 0, behavior: 'smooth' }); };
  return (
    <div>
    
      {/* <!-- Search Modal --> */}
      <div className="modal fade search-modal-area" id="exampleModalsrc">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button type="button" data-bs-dismiss="modal" className="closer-btn">
              <i className="ri-close-line"></i>
            </button>
            <div className="modal-body">
              <form className="search-box">
                <div className="search-input">
                  <input type="text" name="Search" placeholder="Search for..." className="form-control" />
                  <button type="submit" className="search-btn">
                    <i className="ri-search-line"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Search Modal --> */}

      {/* <!-- Start Page Title Area --> */}
      <div className="page-title-area bg-1">
        <div className="container">
          <div className="page-title-content">
            <h2> Gallery </h2>

            <ul>
              <li>
                <Link to="/">
                  Home
                </Link>
              </li>

              <li className="active"> Gallery  </li>
            </ul>
          </div>
        </div>


      </div>
      {/* <!-- End Page Title Area --> */}

      {/* <!-- Start About Area --> */}
      <section className="about-area about-style-three ptb-100">
                <div className="container">
                    <div className="row align-items-center obu">
                        <div className="col-lg-6">
                            <div className="about-content ml-15">
                                <h4>A Collage of Empowerment</h4>
                                <p>Providing the down-trodden children with good health and education are among the prime works of Akoda. 
                                  We expect you to help us create a better picture of the world.</p>
                                <p>A human should stand up for another human. And that noble gesture should go into the annals of 
                                  history of posterity. Today as a civil society we should come together to empower the underprivileged.</p>
                                <p>The best path of empowering children is through good health and education. Let’s support the next 
                                  generation by fostering Akoda fundraisers using crowdfunding and donation for the cause, and be a part of 
                                  the history that has made a positive difference.</p>
                                  
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="about-img-3 mr-15">
                                <img src={im1} className="about-imgs-3" alt="Image" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section id="gallery" >
				<div >
					<div id="image-gallery">
						<div className="section-title">
							<span className="top-title">OUR ACTIVITIES</span>
							<h2>Sample images of some recent work are highlighted</h2>
						</div>
						 <div class="row ">

							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image ">
								<div class="img-wrapper">
									<a href={gallery1}><img src={gallery1} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery2}><img src={gallery2} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery3}><img src={gallery3} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery4}><img src={gallery4} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery5}><img src={gallery5} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery6}><img src={gallery6} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
              <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery7}><img src={gallery7} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
              <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery8}><img src={gallery8} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
              <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery9}><img src={gallery9} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
              <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery10}><img src={gallery10} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
              <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery11}><img src={gallery11} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>
              <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
								<div class="img-wrapper">
									<a href={gallery12}><img src={gallery12} class="img-responsive" /></a>
									<div class="img-overlay">
										{/* <i class="fa fa-plus-circle" aria-hidden="true"></i> */}
									</div>
								</div>
							</div>

						</div> 




	









					</div>
				</div>

			</section>

     
   
    </div>
  )
}

export default Gallery